import { useContext, useEffect } from 'react';
import RichTextEditor from '../QuillEditor/QuillNoSSRWrapper';
import { Button, LoadingButton } from '@s-rm/react-ui-lib';
import {
  DescriptionHeaderWrapper,
  DescriptionWrapper,
  DiagnoseButtonWrapper,
  Notice,
  RichTextEditorWrapper,
  TextFieldWrapper,
} from './styled';
import { useAppDispatch, useAppSelector } from '@/hooks';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  selectQuestionnaireDesription,
  selectDesriptionPreSuggestedIndicators,
  selectQuestionnaireId,
  selectProjectId,
} from '@/store/slices/questionnaire';
import getSuggestedIndicatorsThunk from '@/store/thunks/getSuggestedIndicatorsThunk';
import { selectSuggestedIndicators } from '@/store/slices/indicators';
import {
  selectDescriptionPopoutWindowStatus,
  selectSuggestedIndicatorsStatus,
  setDescriptionPopoutWindowStatus,
  selectIsDescriptionVisible,
  setIsDescriptionVisible,
} from '@/store/slices/ui';
import { windowManager } from '@/utils/windowManager';
import { Routes } from '@/constants/routes';
import { useListenForWindowEventMessages } from '@/hooks/useListenForWindowEventMessages';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDebouncedInputChange } from '@/hooks/useDebounceInputChange';
import { OnlineStatusContext } from '@/context/onlineStatusContext';
import { Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useRouter } from 'next/router';
import { getRootPathFromRouter } from '@/utils/router';
import userActionInterceptor from '@/store/thunks/userActionInterceptor';
import { getFeatureFlagFromStorage } from '../FeatureFlags/utils/checkAndSetFeatureFlag';

const QuestionnaireDescription = () => {
  const router = useRouter();
  const IS_USING_QUESTIONNAIRE_API = getFeatureFlagFromStorage(
    'IS_USING_QUESTIONNAIRE_API'
  );
  const page = getRootPathFromRouter(router);
  const isSpecificsPage = page === Routes.Specifics;
  const { isOnline } = useContext(OnlineStatusContext);
  const questionnaireId = useAppSelector(selectQuestionnaireId);
  const projectId = useAppSelector(selectProjectId);
  const description = useAppSelector(selectQuestionnaireDesription);
  const dispatch = useAppDispatch();
  const suggestedIndicators = useAppSelector(selectSuggestedIndicators);
  const descriptionPreSuggestedIndicators = useAppSelector(
    selectDesriptionPreSuggestedIndicators
  );
  const isPopoutWindowOpen = useAppSelector(
    selectDescriptionPopoutWindowStatus
  );
  const { isLoading: isFetchingIndicators } = useAppSelector(
    selectSuggestedIndicatorsStatus
  );
  const hasSuggestedIndicators = !!suggestedIndicators?.length;

  const { handleChange, value } = useDebouncedInputChange({
    initialValue: description,
    cb: (updatedDescription) => {
      dispatch(
        userActionInterceptor({
          actions: [
            {
              action: 'updateDescriptionThunk',
              payload: updatedDescription,
            },
          ],
        })
      );
    },
  });

  useListenForWindowEventMessages(handleChange, dispatch);

  const showWarning =
    descriptionPreSuggestedIndicators !== value && hasSuggestedIndicators;

  const handleClickGetSuggestedIndicators = () => {
    dispatch(getSuggestedIndicatorsThunk());
  };

  const handleClickOnPopoutWindow = () => {
    const windowURL = `/${Routes.IncidentDescription}/${
      IS_USING_QUESTIONNAIRE_API ? questionnaireId : projectId
    }`;
    dispatch(setDescriptionPopoutWindowStatus({ isOpen: !isPopoutWindowOpen }));
    windowManager(windowURL, '', '');
  };

  const isDescriptionVisible = useAppSelector(selectIsDescriptionVisible);

  const handleCloseDescription = () => {
    dispatch(setIsDescriptionVisible(!isDescriptionVisible));
  };

  return (
    <DescriptionWrapper>
      <DescriptionHeaderWrapper>
        <h4>Description</h4>
        <Box>
          <Button variant='text' square>
            <OpenInNewIcon
              onClick={handleClickOnPopoutWindow}
              data-testid='open-popout-window-btn'
              sx={isPopoutWindowOpen ? { transform: 'rotate(180deg)' } : {}}
            />
          </Button>
          {isSpecificsPage && (
            <IconButton
              aria-label='close-description'
              data-testid='close-description-btn'
              onClick={handleCloseDescription}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DescriptionHeaderWrapper>

      <TextFieldWrapper>
        <RichTextEditorWrapper isPopoutWindowOpen={isPopoutWindowOpen}>
          <RichTextEditor
            {...(isPopoutWindowOpen ? { key: description } : {})}
            id='case-description-input'
            value={value}
            onChange={handleChange}
          />
        </RichTextEditorWrapper>
      </TextFieldWrapper>
      {!!process.env.NEXT_PUBLIC_IS_USING_SUGGESTED_INDICATORS && (
        <DiagnoseButtonWrapper>
          {showWarning && (
            <Notice>
              <InfoOutlinedIcon /> Indicators may be outdated
            </Notice>
          )}
          <LoadingButton
            sx={{ marginLeft: 'auto' }}
            onClick={handleClickGetSuggestedIndicators}
            variant='outlined'
            loading={isFetchingIndicators}
            disabled={!isOnline}
          >
            Diagnose case
          </LoadingButton>
        </DiagnoseButtonWrapper>
      )}
    </DescriptionWrapper>
  );
};

export default QuestionnaireDescription;
